@import '@/styles/breakpoints.module';

.wrapper {
  display: flex;
  justify-content: flex-start;
}

.wrapperSection {
  padding-top: calc(24px + var(--header-full-height));

  --header-full-height: calc(var(--height-header) + var(--height-app-banner));

  .subtitle {
    margin: 16px 0 32px;

    @include tablet {
      margin: 24px 0 40px;
    }
  }

  @include tablet {
    padding-top: calc(40px + var(--header-full-height));
  }

  @include desktop {
    padding-top: calc(60px + var(--header-full-height));
  }
}
