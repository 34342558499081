@import '@/styles/breakpoints.module';

.wrapper {
  margin-top: var(--vstack-spacing-sm);
  border-radius: var(--palette-border-radius-s);
  border: 1px solid var(--palette-gray-150);
  padding: 8px;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      color: var(--palette-gray-900);
    }

    img,
    svg {
      color: var(--color-btn-social-outline-border);
    }
  }

  .subtitle {
    margin-top: 4px;
    color: var(--color-btn-social-outline-border);
  }

  @include tablet {
    margin-top: var(--vstack-spacing-md);
    padding: 12px;
  }

  @include desktop {
    margin-top: var(--vstack-spacing-lg);
  }
}
