@import '@/styles/breakpoints.module';

.bcWrapper {
  overflow: auto;
}

.navigation {
  --bc-bg: var(--palette-gray-600);
  --bc-item-text-color: var(--palette-gray-200);
  --bc-item-text-color-hover: var(--palette-white);
  --bc-item-text-color-active: var(--palette-white);

  width: fit-content;
  white-space: nowrap;
  border-radius: 8px;
  background: var(--bc-bg);
  margin-bottom: 32px;
  padding: 12px 16px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;

  .item,
  .item * {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bc-item-text-color);
  }

  .itemActive,
  .itemActive * {
    font-weight: var(--palette-font-weight-semibold);
    color: var(--bc-item-text-color-active);
  }

  .item:hover,
  .item:hover * {
    color: var(--bc-item-text-color-hover);
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bc-item-text-color);
  }

  @include tablet {
    margin-bottom: 48px;
    padding: 12px 20px 12px 12px;
  }

  @include desktop {
    margin-bottom: 60px;
  }
}
