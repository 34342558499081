.wrapper {
  ol, ul {

    li {
      position: relative;
    }
  }

  ol {
    counter-reset: item;

    li {
      counter-increment: item;

      &:before {
        content: counter(item) '. ';
      }
    }
  }

  ul {
    padding-left: 25px;

    li::before {
      content: '•';
      color: var(--color-btn-social-filled-hover-bg);
      position: absolute;
      left: -12px;
      width: 3px;
      height: 3px;
    }
  }
}
